@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

* {
  box-sizing: border-box;
}
html, body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
}